import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
let appInsights;
let userOid;
export let baseTrackingData = {};

export const setupAppInsights = (instrumentationKey, baseData) => {
  baseTrackingData = baseData;
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();

  if (userOid) {
    appInsights.setAuthenticatedUserContext(userOid);
  }

  return { reactPlugin, appInsights };
};

export const setAuthenticatedUser = (oid) => {
  userOid = oid;

  if (appInsights) {
    appInsights.setAuthenticatedUserContext(userOid);
  }
};
