import { constructEventData, constructViewData } from "./telemetryHelpers";
import { baseTrackingData } from "./AppInsights";

export const useTelemetry = (appInsights) => {
  const sendTelemetryData = (name, data) => {
    if (data) {
      appInsights.trackEvent(
        { name },
        {
          ...baseTrackingData,
          ...data,
        }
      );
    }
  };

  const sendViewEvent = (name, el) => {
    const data = constructViewData(name, el);

    sendTelemetryData("view", data);
  };

  const handleClickTracking = (event) => {
    const data = constructEventData(event);

    if (data?.domPathTokens) {
      sendTelemetryData("click", data);
    }
  };

  return { handleClickTracking, sendViewEvent, sendTelemetryData };
};
