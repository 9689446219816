// The below was taken and customized from https://github.com/Financial-Times/o-tracking
const eventPropertiesToCollect = ["ctrlKey", "altKey", "shiftKey", "metaKey"];
const elementPropertiesToCollect = [
  "nodeName",
  "className",
  "id",
  "href",
  "text",
  "role",
];

// Trim strings
const sanitise = (str) => {
  return typeof str === "string" ? str.trim() : str;
};

// Grabbed from here: https://stackoverflow.com/questions/384286/how-do-you-check-if-a-javascript-object-is-a-dom-object
const isElement = (obj) => {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return (
      typeof obj === "object" &&
      obj.nodeType === 1 &&
      typeof obj.style === "object" &&
      typeof obj.ownerDocument === "object"
    );
  }
};

// Assign the subject value if the target properties are undefined
const assignIfUndefined = (subject, target) => {
  for (const prop in subject) {
    if (!target[prop]) {
      target[prop] = subject[prop];
    } else {
      // eslint-disable-next-line no-console
      console.warn(`You can't set a custom property called ${prop}`);
    }
  }
};

// Get properties for the event (as opposed to properties of the clicked element)
// Available properties include mouse x- and y co-ordinates, for example.
const getEventProperties = (event) => {
  const eventProperties = {};
  for (const property of eventPropertiesToCollect) {
    if (event[property]) {
      try {
        eventProperties[property] = sanitise(event[property]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }

  return eventProperties;
};

const getAllElementProperties = (element) => {
  const properties = {};
  for (const property of elementPropertiesToCollect) {
    const value =
      element[property] ||
      element.getAttribute(property) ||
      element.hasAttribute(property);
    if (value !== undefined) {
      if (typeof value === "boolean") {
        properties[property] = value;
      } else {
        properties[property] = sanitise(value);
      }
    }
  }

  return properties;
};

// Get some properties of a given element.
const getDomPathProps = (attrs, props) => {
  // Collect any attribute that matches given strings.
  attrs
    .filter((attribute) => attribute.name.match(/^data-trackable/i))
    .forEach((attribute) => {
      props[attribute.name] = attribute.value;
    });

  return props;
};

// Get only the custom data-trackable-params-? properties of a given element
const getParamProps = (attrs, props, isOriginalEl) => {
  const customProps = {};

  // for the original element collect properties like className, nodeName
  if (isOriginalEl) {
    elementPropertiesToCollect.forEach((name) => {
      if (typeof props[name] !== "undefined" && name !== "id") {
        customProps[name] = props[name];
      }
    });
  }

  // Collect any attribute that matches given strings.
  attrs
    .filter((attribute) => attribute.name.match(/^data-trackable-params-/i))
    .forEach((attribute) => {
      customProps[attribute.name.replace("data-trackable-params-", "")] =
        attribute.value;
    });

  return customProps;
};

export const getTrace = (el) => {
  const rootEl = document;
  const originalEl = el;
  const trace = [];
  const customParams = {};
  let foundTrackable = false;

  while (el && el !== rootEl) {
    const props = getAllElementProperties(el);
    const attrs = Array.from(el.attributes);
    let domPathProps = getDomPathProps(attrs, props);

    // If the element happens to have a data-trackable attribute, get the siblings
    // and position of the element (relative to the current element).
    if (domPathProps["data-trackable"]) {
      foundTrackable = true;
      trace.push(domPathProps["data-trackable"]);
    }
    if (domPathProps["data-trackable-context"]) {
      trace.push(domPathProps["data-trackable-context"]);
    }

    const paramProps = getParamProps(attrs, props, el === originalEl);

    assignIfUndefined(paramProps, customParams);

    el = el.parentNode;
  }

  return { isTrackable: foundTrackable, trace: trace.reverse(), customParams };
};

export const constructEventData = (clickEvent) => {
  if (!clickEvent) return;

  const clickElement = clickEvent.target;
  // for any elements we don't want to track
  if (
    !isElement(clickElement) ||
    clickElement.getAttribute("data-tracking-do-not-track") === "true"
  ) {
    return;
  }
  const context = getEventProperties(clickEvent);
  const { isTrackable, trace, customParams } = getTrace(clickElement);
  context.domPathTokens = isTrackable ? trace : null;
  context.url = window.document.location.href || null;

  assignIfUndefined(customParams, context);

  // Merge the event data into the "parent" config data
  return context;
};

export const constructViewData = (name, el) => {
  // for any elements we don't want to track
  if (
    !isElement(el) ||
    el.getAttribute("data-tracking-do-not-track") === "true"
  ) {
    return;
  }
  const { trace, customParams } = getTrace(el);
  trace.push(name);

  const context = {
    domPathTokens: trace,
    url: window.document.location.href || null,
  };

  assignIfUndefined(customParams, context);

  // Merge the event data into the "parent" config data
  return context;
};
